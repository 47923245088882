<template>
  <div class="ui-input-file">
    <div class="file-list ui-noselect">
      <a
        v-for="(file,i) in files"
        :key="i"
        :href="file.url"
        target="_system"
      >
        <ui-item
          :icon="'mime:'+file.mimetype"
          :text="file.name"
          :secondary="file.size | bytes"
          class="ui-clickable"
        >
          <template #right>
            <ui-icon
              class="ui-clickable"
              value="mdi:delete"
              @click.prevent="removeFile(i)"
            ></ui-icon>
          </template>
        </ui-item>
      </a>
    </div>

    <file-uploader
      v-if="!maxFiles || files.length < maxFiles"
      :path="path"
      :max-files="maxFiles ? maxFiles - files.length : null"
      @success="onUploadSuccess"
      @error="onUploadError"
    >
      <ui-item
        style="text-align:left"
        icon="mdi:upload"
        :text="placeholder || 'Subir archivo'"
      ></ui-item>
    </file-uploader>
  </div>
</template>

<script>
import { UiIcon, UiItem } from '@/modules/ui/components';
import FileUploader from '@/modules/filesystem/components/File/FileUploader.vue';
import bytes from '@/filters/bytes.js';


export default {
  name: 'ui-input-file',
  components: { UiIcon, UiItem, FileUploader },
  filters: { bytes },

  props: {
    value: {
      type: Array, // El valor es un ARREGLO de objetos tipo archivo (id, name, size, timestamp, url, preview, thumbnail)
      required: false,
      default: () => []
    },

    path: {
      type: String,
      required: true
    },

    placeholder: {
      type: String,
      required: false,
      default: null
    },

    maxFiles: {
      type: [String, Number],
      required: false,
      default: null
    }
  },

  data() {
    return {
      files: []
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.files = newValue ? JSON.parse(JSON.stringify(newValue)) : [];
      }
    }
  },

  methods: {
    onUploadSuccess(files) {
      this.files = this.files.concat(files);
      this.accept();
    },

    onUploadError(err) {
      alert('Error subiendo archivos');
      console.warn(err);
    },

    removeFile(index) {
      if (!confirm('Eliminar este archivo?')) {
        return false;
      }

      this.files.splice(index, 1);
      this.accept();
    },

    clear() {
      this.files = [];
      this.$emit('input', []);
    },

    accept() {
      this.$emit('input', JSON.parse(JSON.stringify(this.files)));
    },

    cancel() {
      this.files = JSON.parse(JSON.stringify(newValue));
      this.$emit('cancel');
    }
  }
};
</script>